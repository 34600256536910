

































































import {
  defineComponent,
  computed,
  ref,
  PropType,
} from '@vue/composition-api';
import { VideoPlayback, VideoPreload } from './types';

// TODO refactor component and remove props/attrs not being used
export default defineComponent({
  name: 'Video',
  inheritAttrs: false,
  props: {
    videoKey: {
      type: String,
      default: null,
    },
    firstFrameImageUrl: {
      type: String,
      required: true,
      default: null,
    },
    isOverlay: {
      type: Boolean,
      default: false,
    },
    videoMuted: {
      type: Boolean,
      default: true,
    },
    isBackground: {
      type: Boolean,
      default: false,
    },
    videoPlayback: {
      type: String as PropType<VideoPlayback>,
      default: VideoPlayback.auto,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    hasPlayIcon: {
      type: Boolean,
      default: false,
    },
    hasPauseIcon: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    preload: {
      type: String as PropType<VideoPreload>,
      default: 'metadata',
    },
  },
  setup(props) {
    const videoRef = ref<HTMLVideoElement>();
    const videoPaused = ref(true);

    const playVideo = async () => {
      if (!videoRef.value?.paused) return;

      await videoRef.value?.play();
    };

    const pauseVideo = () => {
      videoRef.value?.pause();
    };

    const videoBindings = computed(() => ({
      poster: props.firstFrameImageUrl,
      preload: props.preload,
      ...props.videoKey ? { key: props.videoKey } : {},
      ...props.controls ? { controls: true } : {},
      ...props.videoMuted ? { muted: true } : {},
    }));

    return {
      handleVideo() {
        if (videoPaused.value) {
          playVideo();
        } else {
          pauseVideo();
        }
        videoPaused.value = !!videoRef.value?.paused;
      },
      handleVideoPlaying() {
        videoPaused.value = !!videoRef.value?.paused;
      },
      async handleClick() {
        if (props.videoPlayback === VideoPlayback.onClick) {
          await playVideo();
        }
      },
      async handleMouseOver() {
        if (props.videoPlayback === VideoPlayback.onHover) {
          await playVideo();
        }
      },
      handleMouseOut() {
        if (props.videoPlayback === VideoPlayback.onHover) {
          pauseVideo();
        }
      },
      videoRef,
      videoBindings,
      showPlayIcon: computed(() => (!videoPaused.value ? false : props.hasPlayIcon)),
      showPauseIcon: computed(() => (videoPaused.value ? false : props.hasPauseIcon)),
      getContainerClass: computed(() => {
        const wrapperClass= props.isBackground ? 'video__wrapper--background' : 'video__wrapper';
        return {
          [wrapperClass]: true,
          'video-rounded': props.rounded,
        };
      }),
      isAutoPlayback: computed(() => (props.videoPlayback === VideoPlayback.auto ? 'true' : null)),
    };
  },
});
