import {
  library,
} from '@fortawesome/fontawesome-svg-core';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBarcode } from '@fortawesome/free-solid-svg-icons/faBarcode';
import { faBlog } from '@fortawesome/free-solid-svg-icons/faBlog';
import { faBookReader } from '@fortawesome/free-solid-svg-icons/faBookReader';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faBullseye } from '@fortawesome/free-solid-svg-icons/faBullseye';
import { faCashRegister } from '@fortawesome/free-solid-svg-icons/faCashRegister';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCheckCircle as faCheckCircleRegular } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots';
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop';
import { faDumpster } from '@fortawesome/free-solid-svg-icons/faDumpster';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faFemale } from '@fortawesome/free-solid-svg-icons/faFemale';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons/faHandHoldingUsd';
import { faHeadset } from '@fortawesome/free-solid-svg-icons/faHeadset';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faLeaf } from '@fortawesome/free-solid-svg-icons/faLeaf';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons/faMailBulk';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons/faMicrophone';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPollH } from '@fortawesome/free-solid-svg-icons/faPollH';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faRss } from '@fortawesome/free-solid-svg-icons/faRss';
import { faRuler } from '@fortawesome/free-solid-svg-icons/faRuler';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStore } from '@fortawesome/free-solid-svg-icons/faStore';
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags';
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { faHandSparkles } from '@fortawesome/free-solid-svg-icons/faHandSparkles';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons/faLongArrowAltDown';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';

import {
  FontAwesomeIcon,
} from '@fortawesome/vue-fontawesome/';

import {
  fcBag,
  fcChat,
  fcCheckMark,
  fcCircleInfo,
  fcDialog,
  fcFinancing,
  fcFloor,
  fcGift,
  fcGloves,
  fcInfo,
  fcLogo,
  fcPlay,
  fcPlus,
  fcShirt,
  fcStar,
  fcThumbsUp,
  fcThirtyDayRefund,
  fcTrainers,
} from './fc-icons';

library.add([
  faApple,
  faArrowRight,
  faBars,
  faBarcode,
  faBlog,
  faBookReader,
  faBriefcase,
  faBullhorn,
  faBullseye,
  faCashRegister,
  faCheckCircle,
  faCheckCircleRegular,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNotch,
  faComment,
  faCommentDots,
  faDesktop,
  faDumpster,
  faEdit,
  faEnvelope,
  faExclamation,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFacebookF,
  faFacebookSquare,
  faFemale,
  faGoogle,
  faHandHoldingUsd,
  faHandSparkles,
  faHeadset,
  faInfoCircle,
  faInstagram,
  faLeaf,
  faLinkedinIn,
  faLock,
  faLongArrowAltDown,
  faMailBulk,
  faMicrophone,
  faMinus,
  faPhone,
  faPinterest,
  faPlay,
  faPlayCircle,
  faPlus,
  faPollH,
  faQuestion,
  faRss,
  faRuler,
  faShoppingCart,
  faSignOutAlt,
  faStar,
  faStore,
  faTags,
  faTh,
  faTiktok,
  faTimes,
  faTimesCircle,
  faTv,
  faTwitter,
  faUsers,
  faUtensils,
  faYoutube,
  fcBag,
  fcChat,
  fcCheckMark,
  fcCircleInfo,
  fcDialog,
  fcFinancing,
  fcFloor,
  fcGift,
  fcGloves,
  fcInfo,
  fcLogo,
  fcPlay,
  fcPlus,
  fcShirt,
  fcStar,
  fcThirtyDayRefund,
  fcThumbsUp,
  fcTrainers,
]);

export default FontAwesomeIcon;
