/* eslint-disable max-classes-per-file */
import { ShopifyProductVariantGID } from './types';

type CheckoutUserError = {
  field: string,
  message: string,
  code?: string,
}

enum CheckoutUserErrorCodes {
  ProductNotAvailable = 'PRODUCT_NOT_AVAILABLE',
  InvalidForCountry = 'INVALID_FOR_COUNTRY',
}

export type GraphQLError = {
  message: string
}[]

type LineItems = {
  variantId: ShopifyProductVariantGID,
  quantity: number,
}

class ShopifyError extends Error {
  constructor(message: string) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export class ProductAvailabilityError extends ShopifyError {
  products: string[];

  constructor(message: string, cause: any, products: string[]) {
    super(message);
    this.name = 'ProductAvailabilityError';
    this.cause = cause;
    this.products = products;
  }
}

export class ShippingError extends ShopifyError {
  constructor(message: string) {
    super(message);
    this.name = 'ShippingError';
  }
}

export class CouponError extends ShopifyError {
  constructor(message: string) {
    super(message);
    this.name = 'CouponError';
  }
}

export function handleCheckoutUserErrors(errors: CheckoutUserError[], lineItems?: LineItems[]) {
  console.error('CheckoutUserErrors: ', errors);
  const productsNotAvailable = errors.filter((error: CheckoutUserError) => error?.code?.includes(CheckoutUserErrorCodes.ProductNotAvailable) || error?.message?.includes('Variant product is not published for this customer.'));
  const otherErrors = errors.filter((error: CheckoutUserError) => !error?.code?.includes(CheckoutUserErrorCodes.ProductNotAvailable) || !error?.message?.includes('Variant product is not published for this customer.'));

  if (productsNotAvailable.length > 0) {
    const productVariantIds = productsNotAvailable.map((error: CheckoutUserError) => {
      const indexForLineItem = error.field.indexOf('lineItems') + 1;
      const productIndex = parseInt(error.field[indexForLineItem], 10);
      const product = lineItems && lineItems[productIndex];

      return product?.variantId;
    }).filter((id): id is ShopifyProductVariantGID => id !== undefined);

    throw new ProductAvailabilityError('These products are not available in your area.', productsNotAvailable, productVariantIds);
  }

  otherErrors.forEach((error: CheckoutUserError) => {
    if (error?.code?.includes(CheckoutUserErrorCodes.InvalidForCountry)) {
      throw new ShippingError(error.message);
    }
    throw new Error(error.message);
  });
}

const IGNORE_DIGITAL_GOODS_ERROR = "You don't have any items that require shipping";

export function handleGraphQLRequestErrors(errors: GraphQLError) {
  const errorMessages = (errors ?? []).map(e => e.message);
  errorMessages.forEach((error: string) => {
    if (error !== IGNORE_DIGITAL_GOODS_ERROR) {
      throw new ShippingError(error);
    }
  });
}
