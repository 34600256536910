export const productFields = `
  fragment productFields on Product {
    id
    title
    description
    featuredImage {
      url
    }
    images(first: 5) {
      edges {
        node {
          url
        }
      }
    }
    productType
    tags
    variants(first: 50) {
      edges {
        node {
          id
          title
          sku
          price {
            amount
          }
          compareAtPrice {
            amount
          }
          requiresShipping
          uid: metafield(namespace: "fightcamp", key: "uid") {
            value
          }
        }
      }
    }
    stripeSubId: metafield(namespace: "membership", key: "stripeId") {
      value
    }
    stripeTrialDays: metafield(namespace: "membership", key: "trialperioddays") {
      value
    }
    stripeCoupon: metafield(namespace: "membership", key: "coupon") {
      value
    }
    membershipGID: metafield(namespace: "fightcamp", key: "membershipgid") {
      value
    }
    monthlyPrice: metafield(namespace: "membership", key: "monthlyprice") {
      value
    }
  }
`;

// pageInfoFields is used for pagination
export const pageInfoFields = `
  fragment pageInfoFields on PageInfo {
    hasNextPage
    endCursor
  }
`;

export const checkoutFields = `
  fragment checkoutFields on Checkout {
    id
    ready
    email
    requiresShipping
    availableShippingRates {
      ready
      shippingRates {
        handle
        title
        price {
          amount
        }
      }
    }
    lineItemsSubtotalPrice {
      amount
    }
    subtotalPrice {
      amount
    }
    totalPrice {
      amount
    }
    totalTax {
      amount
    }
    totalDuties {
      amount
    }
    paymentDue {
      amount
    }
    shippingLine {
      handle
      title
      price {
        amount
      }
    }
    lineItems(first: 50) {
      edges {
        node {
          id
          title
          quantity
          variant {
            id
            title
            price {
              amount
            }
            compareAtPrice {
              amount
            }
          }
        }
      }
    }
    discountApplications(first: 10) {
      edges {
        node {
          ... on DiscountCodeApplication {
            allocationMethod
            applicable
            targetType
            targetSelection
            code
            value {
              ... on MoneyV2 {
                amount
                currencyCode
              }
              ... on PricingPercentageValue {
                percentage
              }
            }
          }
        }
      }
    }
  }
`;
