/** Main PDP package and upsells */
import fightcamp from './fightcamp';
import partnerWorkoutPack from './partnerWorkoutPack';
import additionalPartnerWorkoutPack from './additionalPartnerWorkoutPack';

/** Holiday 2024 Offers */
import holidayAllOnePackV1 from './holidayAllOnePackV1';
import holidayAllOnePackV2 from './holidayAllOnePackV2';
import holidayAllOnePackV3 from './holidayAllOnePackV3';
import holidayPackV1 from './holidayPackV1';
import holidayPackV2 from './holidayPackV2';
import holidayPackV3 from './holidayPackV3';

export default {
  fightcamp,
  partnerWorkoutPack,
  additionalPartnerWorkoutPack,
  holidayAllOnePackV1,
  holidayAllOnePackV2,
  holidayAllOnePackV3,
  holidayPackV1,
  holidayPackV2,
  holidayPackV3,
};
