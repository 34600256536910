export default {
  title: 'The FightCamp package',
  pathId: 'fightcamp',
  description: 'Our most basic package includes just what you need to experience FightCamp. Track your progress, compete against others, and access our complete library of on-demand workouts.',
  shortDescription: 'Just What You Need to Experience FightCamp',
  testShopifyId: 'gid://shopify/Product/7820642484313',
  prodShopifyId: 'gid://shopify/Product/8701900620012',
  isSoldOut: false,
  primaryImg: {
    src: 'https://a.storyblok.com/f/152150/2000x1053/4d9082f6e7/fc-001-website-v2-shop-test-image.png',
    alt: 'Console with trackers',
  },
  includes: [
    {
      productName: 'Console + 4 Trackers',
      description: 'The console is the heart of the FightCamp system. It holds your 4 trackers that you use to track your punches as well as connects your app to your TV for the best viewing possible for a workout.',
      shortDescription: 'Heart of FightCamp',
      isSoldOut: false,
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/352x352/213b19df90/fc-console-four-trackers-lifestyle-001.png',
        alt: 'FightCamp console with trackers',
      },
      lifestyleImage: {},
      includesModalCopy: {},
      assets: [],
    },
    {
      productName: 'Quick Wraps',
      pathId: 'fightcamp-quick-wraps',
      description: 'These Quick Wraps are built to hold your Trackers in place with wrist-wrapping & knuckle padding to protect your hands.',
      shortDescription: 'You need',
      isSoldOut: false,
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/352x352/5bc6c1c45e/fc-quickwraps-thumbnail-001.png',
        alt: 'FightCamp quick wraps',
      },
      lifestyleImage: {},
      includesModalCopy: {},
      assets: [],
    },
    {
      productName: 'Boxing Bag + Bag Ring',
      pathId: 'fightcamp-boxing-bag',
      description: 'The FightCamp Free-Standing Boxing Bag is the best free-standing bag on the market. It’s beautifully designed to look great in your home. It fits in a just about any space (it’s only 2ft wide and 5ft 7in tall). It holds about 250 lbs of water or 350 lbs of sand (use sand for extra stability). Pair the FightCamp Boxing Bag with the FightCamp Bag Ring to ensure that the boxing bag stays fully in place — even after the toughest workout.',
      shortDescription: 'The FightCamp Free-Standing Boxing Bag is the best free-standing bag on the market.',
      isSoldOut: false,
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/193x192/0cd2f0cf3e/fc-bag-and-ring-001.png',
        alt: 'Boxing bag and ring',
      },
      lifestyleImage: {},
      includesModalCopy: {},
      assets: [],
    },
    {
      productName: 'Boxing Gloves',
      pathId: 'fightcamp-gloves',
      description: '12-month plan included with your package. covers lost or damaged Trackers.',
      shortDescription: 'Boxing Gloves',
      isSoldOut: false,
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/129x128/7f01ed2080/fc-glove-thumbnail-001.png',
        alt: 'FightCamp quick wraps',
      },
      lifestyleImage: {},
      includesModalCopy: {},
      assets: [],
    },
    {
      productName: 'Ankle Wraps',
      pathId: 'fightcamp-ankle-wraps',
      description: '',
      shortDescription: 'Ankle Wraps',
      isSoldOut: false,
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/129x128/4d75affcba/fc-ankle-wrap-001.png',
        alt: 'FightCamp quick wraps',
      },
      lifestyleImage: {},
      includesModalCopy: {},
      assets: [],
    },
    {
      productName: 'Heart Rate Monitor',
      pathId: 'fightcamp-heart-rate-monitor',
      description: '',
      shortDescription: 'Heart Rate Monitor',
      isSoldOut: false,
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/352x352/42889a5470/fc-heart-rate-monitor-person-001.png',
        alt: 'FightCamp quick wraps',
      },
      lifestyleImage: {},
      includesModalCopy: {},
      assets: [],
    },
    {
      productName: 'Mystery Gift ($129+ value)',
      pathId: '',
      description: '',
      shortDescription: 'Mystery Gift',
      isSoldOut: false,
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/226x228/0b3921e7a3/mysterygift.jpg',
        alt: 'mystery gift',
      },
      lifestyleImage: {},
      includesModalCopy: {},
      assets: [],
    },
    {
      productName: 'Free Month of Membership',
      pathId: '',
      description: '',
      shortDescription: 'Free Month of Membership',
      isSoldOut: false,
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/400x400/a65294f990/fc-membership-icon-001.png',
        alt: 'Free Month of Membership',
      },
      lifestyleImage: {},
      includesModalCopy: {},
      assets: [],
    },
  ],
  includesBag: true,
  assets: [
    {
      src: 'https://a.storyblok.com/f/152150/1001x1000/db24b6ae5c/fc-product-carousel-1.jpg',
      alt: 'FightCamp holiday package',
    },
    {
      src: 'https://a.storyblok.com/f/152150/1001x1000/65e0b7e2f9/fc-product-carousel-2.jpg',
      alt: 'Partner workouts',
    },
    {
      src: 'https://a.storyblok.com/f/152150/1001x1000/30aa8d3484/fc-product-carousel-3.jpg',
      alt: 'FightCamp holiday package with tree',
    },
    {
      src: 'https://a.storyblok.com/f/152150/1001x1000/2085a1b94c/fc-product-carousel-4.jpg',
      alt: 'FightCamp quick wraps with trackers',
    },
    {
      src: 'https://a.storyblok.com/f/152150/1001x1000/a5b659c1ff/fc-product-carousel-5.jpg',
      alt: 'Person working out with FightCamp',
    },
  ],
};
