export default {
  title: 'Holiday Pack',
  pathId: '',
  testShopifyId: 'gid://shopify/Product/7824342450265',
  prodShopifyId: 'gid://shopify/Product/8707767632108',
  description: '',
  shortDescription: 'Tons of accessories, plus another month of membership included.',
  isSoldOut: false,
  primaryImg: {
    src: 'https://a.storyblok.com/f/152150/585x600/d2830b229a/fc-marketplace-accessory-kids-gloves-001.png',
    alt: 'Holiday Pack',
  },
  // TODO pull in accessory js files instead of hardcoding here
  includes: [
    {
      productName: 'Quick Wraps',
      id: 'quickwraps',
      description: 'An extra pair of wraps for a friend, family member, or just keep them for yourself. ($39 value)',
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/1001x1000/2085a1b94c/fc-product-carousel-4.jpg',
        alt: 'Quick Wraps',
      },
    },
    {
      productName: 'Traditional wraps',
      id: 'traditionalWraps',
      description: 'Sometimes you want a traditional wrap to add padding & support where you need it most. ($30 value)',
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/226x228/62e95693e6/traditionalwraps.jpg',
        alt: 'Traditional wraps',
      },
    },
    {
      productName: 'Exercise Mat',
      id: 'exerciseMat',
      description: 'Durable and cushioned, it’s your go-to for stretches, sit-ups, and everything in between. ($49 value)',
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/226x228/d3de34ac39/yogamat.jpg',
        alt: 'Exercise Mat',
      },
    },
    {
      productName: 'Jump Rope',
      id: 'jumpRope',
      description: 'The jump rope our trainers love most. 128 inches long and easily adjustable. ($49 value)',
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/226x228/dc19249f3e/jumprope.jpg',
        alt: 'Jump Rope',
      },
    },
    {
      productName: 'Surprise Gift ($15+ value)',
      id: 'surpriseGift',
      description: 'Just a little something extra because ‘tis the season. ($15+ value)',
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/226x228/0b3921e7a3/mysterygift.jpg',
        alt: 'Surprise Gift',
      },
    },
    {
      productName: 'Free Month of Membership',
      id: 'freeMonthMembership',
      description: 'In addition to your 1st free month, your second month of membership is included with this Holiday Pack. ($39 value)',
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/400x400/a65294f990/fc-membership-icon-001.png',
        alt: 'free month of membership',
      },
    },
    {
      productName: '36-month Extended Warranty',
      id: 'extMonthWarranty',
      description: 'Extend your 12-month limited warranty to 36 months, and stay covered longer. ($129 value)',
      primaryImg: {
        src: 'https://a.storyblok.com/f/152150/226x228/755f5167cc/warranty.jpg',
        alt: '36-month Extended Warranty',
      },
    },
  ],
};
