import { Actions } from './actionsTypes';
import { EMAIL_VALIDATOR, PHONE_NUMBER_VALIDATOR, CA_POSTAL_CODE_VALIDATOR, US_POSTAL_CODE_VALIDATOR } from '../utils/regex';
import products from '@/shared/datasets/variants';

export default {
  state: {
    checkoutEmail: '',
    checkoutEmailError: '',
    checkoutEmailValid: false,
    shipping: {
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      phoneNumber: '',
      country: 'US',
    },
    shippingError: '',
    paymentAttemptError: '',
    invalidFields: [],
    shippingDays: [4, 8],
    optInSMS: false,
    paymentInformation: {
      paymentMethod: '',
      token: null,
      cardBrand: '',
      postalCode: '',
    },
    processingPaymentMethod: false,
    processingAddToCart: false,
    marketplaceItemAdded: false,
  },
  getters: {
    hasShippingRestrictedItemsInCart: (_, getters) => !!getters.bundle.find(item => item.shippingRestricted),
    hasCanadaRestrictedItemsInCart: (_, getters) => !!getters.bundle.find(item => item.caShippingRestricted),
    isShippingRestrictedForCart: (state, getters) => getters.hasShippingRestrictedItemsInCart && (state.shipping.state === 'AK' || state.shipping.state === 'HI'),
    itemHasShippingRestrictions: (_, getters) => selectedItemId => {
      const restricted = products.reduce((acc, item) => {
        if (item.shippingRestricted) {
          acc.push(item.product.id);
        }
        return acc;
      }, []);

      return (getters.isGeoLocatedCanada && restricted.includes(selectedItemId));
    },
    getField: (state) => path => path.split(/[.[\]]+/).reduce((prev, key) => prev[key], state),
    processingPaymentMethod: (state) => state.processingPaymentMethod,
    monthlyFinancing: (_, getters) => {
      // TODO add unit test
      const ONE_YEAR = 12;
      const TWO_YEARS = 24;
      const THREE_YEARS = 36;

      if (getters.summary_total < 35000) {
        return getters.summary_total / ONE_YEAR;
      }

      if (getters.summary_total >= 35000 && getters.summary_total < 99500) {
        return getters.summary_total / TWO_YEARS;
      }

      return getters.summary_total / THREE_YEARS;
    }
  },
  mutations: {
    SET_EMAIL(state, email) {
      state.checkoutEmail = email;
    },
    SET_EMAIL_ERROR(state, error) {
      state.checkoutEmailError = error;
    },
    SET_EMAIL_VALID(state, isValid) {
      state.checkoutEmailValid = isValid;
    },
    SET_FIELD(state, { path, value }) {
      path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          // eslint-disable-next-line no-param-reassign
          prev[key] = value;
        }

        return prev[key];
      }, state);
    },
    SET_SHIPPING_ERROR(state, error) {
      state.shippingError = error;
    },
    SET_SHIPPING_INVALID(state, fields) {
      state.invalidFields = fields;
    },
    SET_SMS(state, value) {
      state.optInSMS = value;
    },
    SET_PAYMENT_INFO(state, paymentInfo) {
      state.paymentInformation = {
        ...state.paymentInformation,
        ...paymentInfo
      };
    },
    CLEAR_STRIPE_TOKEN(state) {
      state.paymentInformation = {
        ...state.paymentInformation,
        token: null,
      }
    },
    SET_PAYMENT_ATTEMPT_ERROR(state, err) {
      state.paymentAttemptError = err;
    },
    SET_PROCESSING_PAYMENT_METHOD(state, value) {
      state.processingPaymentMethod = value;
    },
    SET_PROCESSING_ADD_TO_CART(state, value) {
      state.processingAddToCart = value;
    },
    ITEM_ADDED(state) {
      state.marketplaceItemAdded = true;
    },
    SET_SHOPIFY_CHECKOUT_EMAIL(state, value) {
      state.shopifyCheckoutEmail = value;
    },
    SET_CHECKOUT_TAB(state, value) {
      state.currentCheckoutTab = value;
    },
  },
  actions: {
    updateSMS({ commit }, value) {
      commit('SET_SMS', value)
    },
    [Actions.VALIDATE_EMAIL_TAB]: async function ({ state, commit }, apiClient) {
      const { checkoutEmail } = state;

      if (checkoutEmail === '') {
        commit('SET_EMAIL_ERROR', 'Email required.');
        commit('SET_EMAIL_VALID', false);
        return;
      }

      const emailValid = EMAIL_VALIDATOR.test(checkoutEmail);

      if (!emailValid) {
        commit('SET_EMAIL_ERROR', 'Email format invalid.');
        commit('SET_EMAIL_VALID', false);
        return;
      }

      const res = await apiClient.validateEmail(checkoutEmail.toLowerCase());

      if (!res.emailValid) {
        commit('SET_EMAIL_ERROR', 'Invalid Email. Please check for errors or try with another email.');
        commit('SET_EMAIL_VALID', false);
        return;
      }

      commit('SET_EMAIL_VALID', true);
      commit('SET_EMAIL_ERROR', '');
    },
    async updateEmail({ commit }, email) {
      commit('SET_EMAIL', email);
    },
    validateDeliveryTab({ state, commit, getters }) {
      const { address2, country, ...requiredFields } = state.shipping;

      // check for missing fields
      const missingFields = Object.keys(requiredFields).filter(fieldName => state.shipping[fieldName] === '');
      if (missingFields.length > 0) {
        commit('SET_SHIPPING_INVALID', missingFields);
        commit('SET_SHIPPING_ERROR', 'Please fill out all required fields');
        return;
      }

      // check if cart has bag and bag ring for Canada
      const hasFreeStandingBag = getters.bundle.some(product => product.uid === 'freeStandingBag_V2' || product.uid === 'freeStandingBag_V3');
      const hasBagRing = getters.bundle.some(product => product.uid === 'fightcamp_bag_ring_bpi');
      const hasBagButNoRing = hasFreeStandingBag && !hasBagRing;
      if (country === 'CA' && hasBagButNoRing) {
        commit('SET_SHIPPING_INVALID', ['country']);
        commit('SET_SHIPPING_ERROR', 'The Free Standing Bag cannot be shipped alone to Canada. Please add the Bag Ring to your cart.');
        return;
      }

      // TODO how do we validate this for international addresses?
      // const isPostalCodeValid = US_POSTAL_CODE_VALIDATOR.test(requiredFields.postalCode) || CA_POSTAL_CODE_VALIDATOR.test(requiredFields.postalCode.toString().replace(/\W+/g, ''));
      // if (!isPostalCodeValid) {
      //   commit('SET_SHIPPING_INVALID', ['postalCode']);
      //   commit('SET_SHIPPING_ERROR', 'Please check your Zipcode');
      //   return;
      // }

      commit('SET_SHIPPING_ERROR', '');
      commit('SET_SHIPPING_INVALID', []);
    },
    setPaymentInfo({ commit }, paymentInfo) {
      commit('SET_PAYMENT_INFO', paymentInfo)
    },
    clearShippingErrors({ commit }) {
      commit('SET_SHIPPING_ERROR', '');
      commit('SET_SHIPPING_INVALID', []);
    },
    handlePaymentAttemptError({ commit }, error) {
      let errorMsg = error;
      if (error === 'Order is not payable') {
        errorMsg = 'Sorry, something went wrong. Please reach out to Support through Chat, Email (info@joinfightcamp.com), or Phone (1-213-785-3372). '
      }

      commit('SET_PAYMENT_ATTEMPT_ERROR', errorMsg);
      commit('SET_PROCESSING_PAYMENT_METHOD', false);
      commit('CLEAR_STRIPE_TOKEN');
    },
    sendCustomerAnalytics({ state, getters }, { ecommAnalytics, analytics }) {
      ecommAnalytics.setName(state.shipping.firstName, state.shipping.lastName);
      analytics.nameIdentified({ firstName: state.shipping.firstName, lastName: state.shipping.lastName });

      ecommAnalytics.setPhoneNumber(state.shipping.phoneNumber);
      analytics.phoneNumberIdentified(state.shipping.phoneNumber);

      ecommAnalytics.setAddress(state.shipping.address, state.shipping.state, state.shipping.postalCode, state.shipping.city, state.shipping.address2);
      ecommAnalytics.trackCheckoutPayment(getters.bundle);
    },
    setshopifyCheckoutEmail({ commit }, payload) {
      commit('SET_SHOPIFY_CHECKOUT_EMAIL', payload);
    },
    setCheckoutTab({ commit }, payload) {
      commit('SET_CHECKOUT_TAB', payload);
    },
  }
};
