import { useAnalytics } from '@fc/app-common';
import sessionData from '@/shared/utils/sessionData';
import { productAvailabilityError } from '~/utils/errors';
import { formatOrderRequestBody, createOrderTags } from '~/utils/ordersHelpers';
import { transformShopifyProductForAnalytics } from '~/selectors/selectors';
import { createOrUpdateOrder } from './actions/orders/createOrUpdateOrder';

const analytics = useAnalytics();

// Needs this to be true so it does not conflict with the existing shared actions
export const namespaced = true;

// TODO handle errors
class CouponError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CouponError';
  }
}

function getMembership({ state, getters, bugsnag }) {
  if (getters?.membershipInCart) {
    return {
      id: getters?.membershipInCart?.metadata?.stripeSubId,
      trialDays: parseInt(getters.membershipInCart.metadata.stripeTrialDays, 10),
      stripeCoupon: getters.membershipInCart.metadata.stripeCoupon,
    };
  } if (getters?.isHolidayPackageInCart) {
    const getAnnualSub = state?.app?.productsCollection.find(product => product?.variants?.[0]?.uid === 'fightcamp_subscription_yearly_included');

    // TODO throw bugsnag error
    if (!getAnnualSub) bugsnag.notify(new Error('Could not find Annual Subscripition in productsCollection'));
    return {
      id: getAnnualSub?.metadata?.stripeSubId,
      trialDays: parseInt(getAnnualSub.metadata.stripeTrialDays, 10),
      stripeCoupon: getAnnualSub.metadata.stripeCoupon,
    };
  }

  return null;
}

export const actions = {
  async createCheckout({ rootGetters, rootState, commit }, { shippingDetails, cartCoupon = null }) {
    console.info('action - createCheckout', `cartCoupon: ${cartCoupon}`);
    const { checkoutId } = rootState.checkout;

    try {
      commit('setProcessingTotalCost', true, { root: true });
      await createOrUpdateShopifyCheckout({
        shippingDetails, eCommService: this.$eCommService, commit, cart: rootGetters.cart, couponName: cartCoupon, shopifyItemsInCart: rootGetters.cartProducts.shopifyItems,
      }, checkoutId);
    } catch (err) {
      // this is an error not handled in createOrUpdateShopifyCheckout, so we need to report it
      this.$bugsnag.notify(err);
      commit('SET_SHIPPING_ERROR', 'We\'re sorry, an error occured. Please try again.', { root: true });
    } finally {
      commit('setProcessingTotalCost', false, { root: true });
    }
  },

  async updateCheckout({ rootGetters, rootState, commit }, { shippingDetails = null, cartCoupon = null } = {}) {
    const { checkoutId } = rootState.checkout;

    try {
      commit('setProcessingTotalCost', true, { root: true });

      await createOrUpdateShopifyCheckout({
        shippingDetails, eCommService: this.$eCommService, commit, cart: rootGetters.cart, couponName: cartCoupon, shopifyItemsInCart: rootGetters.cartProducts.shopifyItems,
      }, checkoutId);
    } catch (err) {
      // this is an error not handled in createOrUpdateShopifyCheckout, so we need to report it
      this.$bugsnag.notify(err);
      commit('SET_SHIPPING_ERROR', 'We\'re sorry, an error occured. Please try again', { root: true });
    } finally {
      commit('setProcessingTotalCost', false, { root: true });
    }
  },

  async getOrder({ rootState }) {
    console.info('action - getOrder');
    try {
      const { paymentIntentId, checkoutId } = rootState.checkout;
      if (!paymentIntentId || !checkoutId) { return null; }

      const {
        totalPrice, subTotal, totalTax, shippingRates, discountAmount, couponCode, couponData,
      } = await getCheckoutUntilReady({ checkoutId, eCommService: this.$eCommService });

      return {
        order: {
          costBreakdown: {
            totalCost: totalPrice,
            productTax: totalTax,
            productCost: subTotal,
            // TODO refactor coupon data to one obj once isShopifyOn is removed, did not want to mess with existing pattern
            ...(discountAmount ? { discountAmount } : {}),
            ...(couponCode ? { couponName: couponCode } : {}),
            ...(couponData ? { couponData } : {}),
            productShipping: shippingRates ? shippingRates[0]?.price : null,
          },
          shippingRateDetails: shippingRates ? shippingRates[0] : null, // had to add this to be able to update order in applycoupon
        },
      };
    } catch (err) {
      console.error(err);
      this.$bugsnag.notify(err);

      return null;
    }
  },
  createOrUpdateOrder,

  async confirmOrder({
    commit, rootState, rootGetters,
  }) {
    commit('SET_PROCESSING_PAYMENT_METHOD', true, { root: true });
    commit('SET_PAYMENT_ATTEMPT_ERROR', '', { root: true });

    console.info('action - confirmOrder');
    const {
      paymentIntentId, draftOrderId, setupIntentId, shipping, cart, customerId, affirmToken, order: { costBreakdown, shippingRateDetails }, new_customer: newCustomer, checkoutEmail: email,
    } = rootState.checkout;
    const { cartProducts } = rootGetters;
    try {
      if (!draftOrderId) {
        throw new Error('Draft Order ID is missing');
      }

      if (paymentIntentId) {
        // need to update the Payment Intent with the customerId so that it attaches the customer to the card for subscriptions
        // and lets also update shipping details, cart, costBreakdown in case they have changed
        const cartItems = cart.map((item) => ({
          id: item.id,
          qty: item.qty,
          type: item.type,
          uid: cartProducts?.shopifyItems?.find((product) => product.variant.id === item.id)?.variant?.uid ?? null,
        }));
        const tags = createOrderTags({ isEligibleGen2Upgrade: rootGetters.isEligibleGen2Upgrade, includesConsole: rootGetters.includesConsole });
        const updateRequestBody = formatOrderRequestBody({
          shippingDetails: shipping, cart: cartItems, costBreakdown, customerId, draftOrderId, tags,
        });

        await this.$ordersApi.updateOrder({ paymentIntentId, requestBody: updateRequestBody });
      }

      const membership = getMembership({ state: rootState, getters: rootGetters, bugsnag: this.$bugsnag });

      const productList = cart.map((item) => ({
        id: item.id,
        qty: item.qty,
        type: item.type,
        uid: rootGetters?.cartProducts?.shopifyItems?.find((product) => product.variant.id === item.id)?.variant?.uid ?? null,
      }));

      const requestBody = {
        email,
        shippingInfo: {
          address: shipping.address,
          address2: shipping.address2,
          city: shipping.city,
          state: shipping.state,
          country: shipping.country,
          postalCode: shipping.postalCode,
          firstName: shipping.firstName,
          lastName: shipping.lastName,
          phoneNumber: shipping.phoneNumber,
          email,
        },
        costBreakdown,
        shippingRateDetails,
        productList,
        membership,
        affirmLoanId: affirmToken,
        customerId,
        paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id,
        options: {
          paymentMethod: rootState.checkout.paymentInformation?.paymentMethod,
          retail: null,
          insideSale: null,
        },
        sessionData: sessionData.get(),
        newCustomer,
        paymentIntentId,
        draftOrderId,
        setupIntentId,
        anonymousId: typeof window?.analytics?.user === 'function' ? window.analytics.user()?.anonymousId() : null,
      };

      const {
        data: {
          orderName, orderID, method, experiments,
        },
      } = await this.$ordersApi.confirmOrder({ requestBody });

      commit('setConfirmation', {
        email,
        textPhoneNumber: shipping.phoneNumber,
        orderNumber: parseInt(orderName.split('#')[1], 10), // orderNumber needs to be an integer and cannot have # in it
        orderID,
        order: rootState.checkout.order,
        method,
        cart,
        isNewCustomer: newCustomer,
        serverExperiments: experiments, // TODO
        name: `${shipping.firstName} ${shipping.lastName}`,
        subscription: rootGetters.membershipInCart,
      }, { root: true });

      const products = rootGetters?.cartProducts?.shopifyItems?.map(item => transformShopifyProductForAnalytics({ product: item, variantId: item?.variant?.id }));

      analytics.orderCompleted({ ...rootGetters.confirmation, products, packageInCart: !!rootGetters.packageInCart });

      commit('reset', null, { root: true });
    } catch (err) {
      this.$bugsnag.notify(err);
      if (err?.response?.data.type === 'StripeError') {
        throw new Error(err.response.data.message);
      }

      if (err?.response?.data.type === 'ShopifyError' && err?.response?.data.message === 'Error completing draft order in Shopify') {
        throw new Error('Sorry, there was an error placing the order. Please refresh and try again.');
      }

      if (err.message === 'Draft Order ID is missing') {
        throw new Error('Sorry, there was an error placing the order. Please refresh and try again.');
      }

      throw new Error(err);
    }
  },
  async getCustomer({ commit }, payload) {
    console.info('action - getCustomer');
    let customer = {};

    try {
      const { email, id } = payload;
      const response = await this.$ordersApi.getCustomer({ email, id });
      [customer] = response.data;

      if (customer) {
        commit('updateCustomerId', customer.id, { root: true });
        commit('setNewCustomer', customer.newSubCustomer, { root: true });
      }

      return customer;
    } catch (err) {
      // TODO handle errors
      console.error(err);
      this.$bugsnag.notify(err);

      throw new Error(err);
    }
  },

  // handles sending a request to create a new customer from backend
  async createCustomer({ commit }, payload) {
    console.info('action - createCustomer');
    let createdCustomer = {};

    try {
      const { shippingDetails } = payload;

      const requestBody = {
        email: shippingDetails.email,
        shippingDetails: {
          name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
          phone: shippingDetails.phoneNumber,
          address: {
            line1: shippingDetails.address,
            line2: shippingDetails.address2,
            city: shippingDetails.city,
            state: shippingDetails.state,
            country: shippingDetails.country,
            postal_code: shippingDetails.postalCode,
          },
        },
      };

      createdCustomer = await this.$ordersApi.createCustomer(requestBody);

      commit('updateCustomerId', createdCustomer.data.id, { root: true });
      commit('setNewCustomer', createdCustomer.data.newSubCustomer, { root: true });

      return createdCustomer.data;
    } catch (err) {
      // TODO handle errors
      console.error(err);
      this.$bugsnag.notify(err);

      throw new Error(err);
    }
  },

  async updateCustomer({ rootState }, payload = {}) {
    console.info('action - updateCustomer');
    let updatedCustomer = null;
    const { shippingDetails = null, id: stripeId } = payload;

    try {
      const id = stripeId || rootState.checkout.customerId;

      const requestBody = {
        paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id ?? null,
        ...(shippingDetails && {
          shippingDetails: {
            name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
            phone: shippingDetails.phoneNumber,
            address: {
              line1: shippingDetails.address,
              line2: shippingDetails.address2,
              city: shippingDetails.city,
              state: shippingDetails.state,
              country: shippingDetails.country,
              postal_code: shippingDetails.postalCode,
            },
          },
        }),
      };

      updatedCustomer = await this.$ordersApi.updateCustomer({ id, requestBody });

      return updatedCustomer.data;
    } catch (err) {
      this.$bugsnag.notify(err);

      if (err?.response?.data.type === 'StripeError') {
        throw new Error(err.response.data.message);
      } else {
        throw new Error(err);
      }
    }
  },

  async applyCoupon({ rootState, rootGetters, commit }, coupon) {
    console.info('action - applyCoupon');
    const { checkoutId, checkoutEmail, shipping } = rootState.checkout;

    try {
      commit('setProcessingTotalCost', true, { root: true });

      const shippingDetails = { ...shipping, email: checkoutEmail };

      const checkoutObj = await createOrUpdateShopifyCheckout({
        shippingDetails, eCommService: this.$eCommService, commit, cart: rootGetters.cart, couponName: coupon,
      }, checkoutId);

      if (checkoutObj) {
        commit('updateCouponError', '', { root: true });
        commit('updateCoupon', coupon, { root: true });
        commit('setProcessingTotalCost', false, { root: true });
      }
    } catch (err) {
      // this is an error not handled in createOrUpdateShopifyCheckout, so we need to report it
      this.$bugsnag.notify(err);
      commit('SET_SHIPPING_ERROR', 'We\'re sorry, an error occured. Please try again', { root: true });
      commit('setProcessingTotalCost', false, { root: true });
    }
  },
  async removeCoupon({ rootState, commit }) {
    console.info('action - removeCoupon');
    const { checkoutId } = rootState.checkout;
    try {
      commit('setProcessingTotalCost', true, { root: true });

      const checkoutObj = await createOrUpdateShopifyCheckout({
        eCommService: this.$eCommService, commit, removeCoupon: true,
      }, checkoutId);

      if (checkoutObj) {
        commit('updateCouponError', '', { root: true });
        commit('updateCoupon', '', { root: true });
        commit('setProcessingTotalCost', false, { root: true });
      }
    } catch (err) {
      // this is an error not handled in createOrUpdateShopifyCheckout, so we need to report it
      this.$bugsnag.notify(err);
      commit('SET_SHIPPING_ERROR', 'We\'re sorry, an error occured. Please try again', { root: true });
      commit('setProcessingTotalCost', false, { root: true });
    }
  },
  async calculateEstimatedTax({ commit }, payload) {
    try {
      const res = await this.$ordersApi.createTax(payload);
      const { amountToCollect, rate } = res.data;
      commit('setEstimatedTax', { amountToCollect, rate }, { root: true });
    } catch (err) {
      commit('setEstimatedTaxError', err.response.data.message, { root: true });
    }
  },
};

/*
  Recursive function that calls Shopify API query getCheckout until the checkout is ready
  if for some reason the checkout is not ready after 20 seconds we stop making calls to Shopify
  and throw an error
*/
async function getCheckoutUntilReady({ checkoutId, eCommService, startTime = Date.now() }) {
  // If more than 20 seconds have passed since the first call, throw an error
  if (Date.now() - startTime > 20000) {
    throw new Error('Sorry, there was an error signing you up. Please try again.');
  }

  const checkoutData = await eCommService.getCheckout(checkoutId);
  if (checkoutData.isReady) {
    return checkoutData;
  }
  // Wait for 2 seconds before the next API call
  await new Promise(resolve => setTimeout(resolve, 2000));
  return getCheckoutUntilReady({ checkoutId, eCommService, startTime });
}

/**
 * Creates a Shopify checkout with the provided shipping details and updates the store's order object.
 *
 * @param {Object} options - The options for creating the Shopify checkout.
 * @param {string} checkoutId - The Shopify Checkout ID if it already exists.
 * @param {Object} options.shippingDetails - The shipping details for the checkout.
 * @param {Object} options.eCommService - The e-commerce service used to create the checkout.
 * @param {Function} options.commit - The Vuex commit function used to update the store.
 * @param {Array} options.cart - The cart items to be included in the checkout.
 * @param {string} options.couponName - The coupon to be applied to the checkout.
 * @param {boolean} options.removeCoupon - Whether to remove the coupon from the checkout.
 * @param {Array} options.shopifyItemsInCart - The Shopify items in the cart.
 * @returns {Promise<Object>} - The order object for the created checkout.
 */
async function createOrUpdateShopifyCheckout({
  shippingDetails = null, eCommService, commit, cart = [], couponName, removeCoupon = false, shopifyItemsInCart = [],
}, checkoutId = null) {
  let shippingAddress = null;

  if (shippingDetails) {
    const {
      address, address2, city, state, country, firstName, lastName, phoneNumber, postalCode,
    } = shippingDetails;

    shippingAddress = {
      address1: address,
      address2,
      city,
      province: state,
      country,
      firstName,
      lastName,
      phone: phoneNumber,
      zip: postalCode,
    };
  }

  const request = {
    ...(shippingDetails?.email && { email: shippingDetails.email }),
    ...(shippingAddress && { shippingAddress }),
    lineItems: cart.map((item) => ({
      variantId: item.id,
      quantity: item.qty,
    })),
  };
  let id = checkoutId;

  try {
    if (id) {
      const updatedCheckout = await eCommService.updateCheckout({ ...request, checkoutId: id });
      id = updatedCheckout.checkoutId;

      if (updatedCheckout.error) {
        throw new Error(updatedCheckout.error);
      }

      // Apply Coupon
      if (couponName) {
        const updatedCouponCheckout = await eCommService.applyCoupon({ checkoutId: id, couponCode: couponName });

        if (updatedCouponCheckout.checkoutId) {
          id = updatedCouponCheckout.checkoutId;
        }

        /*
        Shopify will not give an error since the coupon is a valid one.
        If items are not part of the coupon it will just return the order with no discount applied
        TODO figure out why it does not return as https://community.shopify.com/c/hydrogen-headless-and-storefront/handle-discounts-via-the-storefront-api/m-p/1308445
        */
        if (updatedCouponCheckout.errors.length > 0) {
          // only handling showing the first error for now
          throw new CouponError(updatedCouponCheckout.errors[0]);
        }
      }

      // remove coupon
      if (removeCoupon) {
        const updatedRemoveCoupCheckout = await eCommService.removeCoupon({ checkoutId: id });

        if (updatedRemoveCoupCheckout.checkoutId) {
          id = updatedRemoveCoupCheckout.checkoutId;
        }

        if (updatedRemoveCoupCheckout.errors.length > 0) {
          throw new CouponError('We are sorry. There was an error removing your promo code', { root: true });
        }
      }

      commit('setCheckoutId', id, { root: true });
    } else {
      const createdCheckout = await eCommService.createCheckout(request);
      commit('setCheckoutId', createdCheckout.checkoutId, { root: true });
      id = createdCheckout.checkoutId;

      // Apply Coupon
      if (couponName) {
        const updatedCouponCheckout = await eCommService.applyCoupon({ checkoutId: id, couponCode: couponName });

        if (updatedCouponCheckout.checkoutId) {
          id = updatedCouponCheckout.checkoutId;
        }

        /*
        Shopify will not give an error since the coupon is a valid one.
        If items are not part of the coupon it will just return the order with no discount applied
        TODO figure out why it does not return as https://community.shopify.com/c/hydrogen-headless-and-storefront/handle-discounts-via-the-storefront-api/m-p/1308445
        */
        if (updatedCouponCheckout.errors.length > 0) {
          // only handling showing the first error for now
          throw new CouponError(updatedCouponCheckout.errors[0]);
        }
      }
    }

    const {
      shippingRates,
    } = await getCheckoutUntilReady({ checkoutId: id, eCommService });

    // shippingRates may be null if the product does not require shipping or empty array
    if (shippingRates && shippingRates?.length > 0) {
      console.log('shippingRates:', shippingRates, 'shippingRates[0]:', shippingRates[0], 'id:', id);
      await eCommService.updateCheckoutShippingLine({ checkoutId: id, shippingRateHandle: shippingRates[0].id });
    }

    // TODO move these calculations to seperate function and can move coupon calls out of this function as well?
    // get these values at the end until all the mutations are done and data is ready
    const {
      subTotal, totalPrice, totalTax, shippingAmount, totalDuties, discountAmount, couponCode, couponData,
    } = await getCheckoutUntilReady({ checkoutId: id, eCommService });

    // format Shopify checkout data to match our old order object format, this means minimal changes to the store. Can be refactored later.
    const orderObj = {
      costBreakdown: {
        totalCost: totalPrice,
        productTax: totalTax,
        productCost: subTotal,
        productShipping: shippingAmount,
        ...(discountAmount ? { discountAmount } : {}),
        ...(couponCode ? { couponName: couponCode } : {}), // follow existing pattern
        ...(couponData ? { couponData } : {}),
      },
      shippingRateDetails: shippingRates ? shippingRates[0] : null,
      totalDuties,
    };
    console.log('orderObj:', orderObj);
    commit('updateOrder', orderObj, { root: true });

    return orderObj;
  } catch (err) {
    // we handle and commit all known errors here so that in the action we only commit unknown errors and those are reported to bugsnag

    const errorHandlers = {
      ProductAvailabilityError: () => {
        productAvailabilityError({ commit, products: err.products, shopifyItemsInCart });
        return null;
      },
      ShippingError: () => {
        if (err.message === 'MISSING_SHIPPING_RATE') {
          commit('SET_SHIPPING_ERROR', 'Product currently not available.', { root: true });
        } else {
          commit('SET_SHIPPING_ERROR', err.message, { root: true });
        }
        return null;
      },
      CouponError: () => {
        commit('updateCouponError', err.message, { root: true });
        return null;
      },
    };

    // Execute the handler based on err.name, or throw an error if not found
    if (errorHandlers[err.name]) {
      const result = errorHandlers[err.name]();
      commit('setProcessingTotalCost', false, { root: true });
      if (result !== undefined) {
        return result;
      }
    } else {
      throw new Error(err.message);
    }
    return null;
  }
}
