/* eslint-disable @typescript-eslint/no-shadow */

import JobPostingsService from '~/services/JobPostingsService';
import Bugsnag from '~/services/BugSnagService';
import { CANADA_TIME_ZONES } from '~/constants/canadaTimeZones';
import { runLocalToggleOverrideInitializeEffect } from './effects/runLocalToggleOverrideInitializeEffect';
import { runOneTrustEffect } from './effects/runOneTrustEffect';
import { getExperimentById } from './getters/getExperimentById';
import storeMutations from './constants/storeMutations';
import navTypes from './constants/navTypes';

export const namespaced = false;

export const state = () => ({
  pageTransition: false,
  navType: navTypes.primary,
  navigationData: null,
  suppressModal: false,
  isSliderVisible: false,
  showEmailInput: true,
  experimentsInitialized: {},
  isGrowthbookInitialized: false,
  experiments: [],
  featureFlags: [],
  showShopButton: false,
  appInitialized: false,
  jobPostings: [],
  appStoreReviewsCount: '10,000+',
  isInStoryblok: false,
  currentRoute: {},
  packagePageTotal: 0,
  geoLocationCountry: null,
  products: [],
  productsCollection: [],
  shippingZones: [],
  isShopifyOn: process.env.NUXT_ENV_SHOPIFY_ON === 'true' ?? false,
  appVersion: process.env.appVersion ?? '0.0.0',
  packageBuilder: {
    package: {},
    sizeVariant: {},
  },
});

export const mutations = {
  [storeMutations.SET_NAV_TYPE](state, type) {
    state.navType = type;
  },
  [storeMutations.SET_NAV_DATA](state, data) {
    state.navigationData = data;
  },
  [storeMutations.SET_MODAL_SUPPRESSION](state, boolean) {
    state.suppressModal = boolean;
  },
  [storeMutations.SET_SLIDER_VISIBLE](state, boolean) {
    state.isSliderVisible = boolean;
  },
  [storeMutations.TOGGLE_SHOW_EMAIL_INPUT](state) {
    state.showEmailInput = !state.showEmailInput;
  },
  [storeMutations.SET_SHOW_EMAIL_INPUT](state, boolean) {
    state.showEmailInput = boolean;
  },
  [storeMutations.REGISTER_EXPERIMENT](state, experiment) {
    if (experiment) {
      state.experimentsInitialized[experiment.id] = true;
      state.experiments.push(experiment);
    }
  },
  [storeMutations.HIDE_SHOP_BUTTON](state, boolean) {
    state.showShopButton = !boolean;
  },
  [storeMutations.ADD_JOB_POSTINGS](state, jobPostings) {
    state.jobPostings = jobPostings;
  },
  SET_APP_INITIALIZED(state, boolean) {
    state.appInitialized = boolean;
  },
  [storeMutations.IS_IN_STORYBLOK](state, boolean) {
    state.isInStoryblok = boolean;
  },
  [storeMutations.SET_CURRENT_ROUTE](state, route) {
    state.currentRoute = route;
  },
  [storeMutations.SET_PACKAGE_PAGE_TOTAL](state, total) {
    state.packagePageTotal = total;
  },
  [storeMutations.SET_GEOLOCATION_COUNTRY](state, country) {
    state.geoLocationCountry = country;
  },
  [storeMutations.REGISTER_GROWTHBOOK_EXPERIMENTS](state, experiment) {
    state.experiments.push(experiment);
    if (process.client && window && window.clarity) {
      window.clarity('set', experiment.id, experiment.variant);
    }
  },
  [storeMutations.SET_GROWTHBOOK_INITIALIZED](state, boolean) {
    state.isGrowthbookInitialized = boolean;
  },
  [storeMutations.SET_FEATURE_FLAG](state, { key, isOn }) {
    state.featureFlags.push({ key, isOn });
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_COLLECTION(state, collection) {
    state.productsCollection = collection;
  },
  SET_SHIPPING_ZONES(state, zones) {
    state.shippingZones = zones;
  },
  SET_PACKAGE_BUILDER(state, data) {
    state.packageBuilder[data.step] = data.productData;
  },
};

export const actions = {
  async getJobPostings(context) {
    await JobPostingsService.getJobPostings().then((response) => {
      context.commit('ADD_JOB_POSTINGS', response.data);
    });
  },
  initializeApp(context) {
    if (process.client && window) {
      runLocalToggleOverrideInitializeEffect(context, window.location.search);
      runOneTrustEffect(window);
    }
    context.commit('SET_APP_INITIALIZED', true);
  },
  async getProducts({ commit }) {
    const resp = await this.$eCommService.getProducts();
    commit('SET_PRODUCTS', resp);
  },
  async getCollection({ commit }, { id }) {
    try {
      const resp = await this.$eCommService.getCollection({ id });
      commit('SET_COLLECTION', resp);
    } catch (err) {
      Bugsnag.notify(err);
    }
  },
  packageBuilderStep1({ commit }, data) {
    commit('SET_PACKAGE_BUILDER', data);
  },
};

export const getters = {
  isShopifyOn: state => state.isShopifyOn,
  appVersion: (state) => state.appVersion,
  suppressModal: state => state.suppressModal,
  appInitialized: state => state.appInitialized && state.isGrowthbookInitialized,
  navType: state => state.navType,
  navigationData: state => state.navigationData,
  pageTransition: state => state.pageTransition,
  getExperimentById: state => id => getExperimentById(state, id),
  isFeatureOn: state => key => state.featureFlags.some(flag => flag.key === key && flag.isOn),
  showShopButton: state => state.showShopButton,
  isExperimentInitialized: state => id => state.experimentsInitialized[id],
  isInStoryblok: state => state.isInStoryblok,
  currentRoute: state => state.currentRoute,
  packagePageTotal: state => state.packagePageTotal,
  isGeoLocatedCanada: state => state.geoLocationCountry === 'CA',
  allProducts: state => state.products,
  productsCollection: state => state.productsCollection,
  subscriptionProducts: (_, getters) => getters.productsCollection.filter(product => product.productType === 'subscription'),
  packageSelected: state => state.packageBuilder.package,
  variantSelected: state => state.packageBuilder.sizeVariant,
  isTimezoneCanada: () => {
    if (typeof Intl !== 'undefined' && typeof Intl.DateTimeFormat === 'function') {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return CANADA_TIME_ZONES.includes(tz);
    }

    return false;
  },
};
