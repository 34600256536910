// eslint-disable-next-line import/no-extraneous-dependencies
import { StorefrontApiClient } from '@shopify/storefront-api-client';
import {
  handleGraphQLRequestErrors, handleCheckoutUserErrors, ShippingError, CouponError,
} from './errors';
import {
  collectionQuery, productsQuery, checkoutCreateMutation, checkoutQuery, checkoutShippingAddressUpdateMutation, checkoutShippingLineUpdateMutation, checkoutLineItemsUpdateMutation, checkoutEmailUpdateMutation,
  removeCouponQuery, applyCouponQuery,
} from './queries';
import {
  FetchAllPagesParams, QueryCollectionReponse, QueryProductsResponse, GetProductsOrCollectionDataReturn, IShopifyProduct, IShopifyProductVariant, FormattedProduct,
  ICheckout, ShopifyShippingRate, ICouponResponse, ShopifyProductVariantGID,
} from './types';

/*
  error format response from StorefrontApiClient
  resp: {
    errors: {
      graphQLErrors: {message: 'error in syntax at line 12'}[],
      message: 'GraphQL Client: An error occurred while fetching from the API. Review 'graphQLErrors' for details.',
      networkStatusCode: number,
      response:  object,
    }
  }
*/

/*
  TODO refactor below to two seperate files
  1. ShopifyWrapper.ts (holds most of the logic below tied to Shopify)
  2.Shopify/eCommService.ts or could be index file

  ShopifyWrapper's job is to hold all of the Shopify logic/knowledge and eCommService's job
  is to format it nicely so it would be easy to switch away from Shopify.
  https://github.com/fightcamp/fightcamp-web-monorepo/pull/948#issuecomment-1998130241
*/

type LineItems = {
  variantId: ShopifyProductVariantGID,
  quantity: number,
}

type createCheckout = {
  email: string,
  shippingAddress: any,
  lineItems: LineItems[]
}

export class ShopifyService {
  private client: StorefrontApiClient;

  fetchAllPages: ({
    query, id, numProducts, cursor,
  }: FetchAllPagesParams) => Promise<IShopifyProduct[]>;

  constructor(shopifyClient: StorefrontApiClient) {
    this.client = shopifyClient;

    // fetchAllPages is a recursive function that will fetch all pages of Products and Collection query
    this.fetchAllPages = async ({
      query, id, numProducts = 100, cursor,
    }: FetchAllPagesParams) => {
      const variables = {
        ...(id && { id }),
        numProducts,
        ...(cursor && { cursor }),
      };

      const resp = await this.client.request(query, { variables });

      if (resp.errors) {
        const errorMessages = (resp?.errors?.graphQLErrors ?? []).map(e => e.message);
        errorMessages.forEach((error: string) => {
          throw new Error(error);
        });
      }

      // handle getting products or collection data from response
      const { productData, hasNextPage, endCursor } = getProductsOrCollectionData(resp as QueryProductsResponse | QueryCollectionReponse);

      if (hasNextPage) {
        const nextPageProductData = await this.fetchAllPages({
          query, cursor: endCursor, id, numProducts,
        });
        return [...productData, ...nextPageProductData];
      }
      return productData;
    };
  }

  async getProducts(): Promise<FormattedProduct[]> {
    try {
      const productsData = await this.fetchAllPages({ query: productsQuery });
      const productsResponse = this.formatProductData(productsData);

      return productsResponse;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  /*
    shopify id format example: gid://shopify/Collection/277084504153
  */
  async getCollection({ id }: { id: string }): Promise<FormattedProduct[]> {
    try {
      const collectionData = await this.fetchAllPages({ query: collectionQuery, id });
      const collectionResponse = this.formatProductData(collectionData);

      return collectionResponse;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getCheckout(checkoutId: string): Promise<ICheckout> {
    try {
      const { data, errors } = await this.client.request(checkoutQuery, {
        variables: {
          id: checkoutId,
        },
      });

      if (errors && errors.graphQLErrors) {
        console.log('graphQLErrors: ', errors);
        handleGraphQLRequestErrors(errors.graphQLErrors);
      }

      if (!data.node) {
        return {
          isReady: false,
          requiresShipping: false,
          shippingRates: null,
          subTotal: 0,
          totalPrice: 0,
          totalTax: 0,
          totalDuties: 0,
          shippingAmount: null,
        };
      }

      // added lineItemsSubtotalPrice since it does not reflect the discount so need to use this instead. Could also add another value saying originalSubPrice/etc
      const {
        shippingLine, ready, availableShippingRates, subtotalPrice, lineItemsSubtotalPrice, totalPrice, totalTax, totalDuties, requiresShipping, discountApplications,
      } = data.node;
      console.log(data.node);

      if (ready && requiresShipping && availableShippingRates?.shippingRates?.length === 0) {
        throw new ShippingError('MISSING_SHIPPING_RATE');
      }

      // need to calculate percentage amount for discount in costBreakdown & refactor
      // TODO refactor
      const hasDiscount = discountApplications.edges.length > 0;
      let discountCalc;
      let discountType;
      let discountPrice;

      const lineItemsSubtotalPriceAmount = this.convertToIntegerWithoutDecimal(lineItemsSubtotalPrice.amount);
      const subTotalPriceAmount = this.convertToIntegerWithoutDecimal(subtotalPrice.amount);

      if (hasDiscount) {
        // TODO refact and make discountTypes constants
        discountType = discountApplications.edges[0].node.value.amount ? 'FIXED_AMOUNT' : 'PERCENTAGE';
        if (discountType === 'FIXED_AMOUNT') {
          discountCalc = this.convertToIntegerWithoutDecimal(discountApplications.edges[0].node.value.amount);
          discountPrice = parseFloat(discountApplications.edges[0].node.value.amount);
        } else {
          // TODO handle shipping coupons
          // should I actually do the percentage calculation here? or what if this is a shipping coupon ??
          discountCalc = lineItemsSubtotalPriceAmount - subTotalPriceAmount;
          discountPrice = discountApplications.edges[0].node.value.percentage;
        }
      }

      return {
        isReady: ready,
        requiresShipping,
        shippingRates: ready ? availableShippingRates?.shippingRates
          .map((rate: ShopifyShippingRate) => ({
            id: rate.handle,
            title: rate.title,
            price: this.convertToIntegerWithoutDecimal(rate.price.amount),
          })) : null,
        subTotal: this.convertToIntegerWithoutDecimal(lineItemsSubtotalPrice.amount),
        totalPrice: this.convertToIntegerWithoutDecimal(totalPrice.amount),
        totalTax: this.convertToIntegerWithoutDecimal(totalTax.amount),
        totalDuties: (ready && totalDuties) ? this.convertToIntegerWithoutDecimal(totalDuties.amount) : 0,
        ...(hasDiscount ? { discountAmount: discountCalc } : {}),
        ...(hasDiscount ? { couponCode: discountApplications?.edges[0]?.node?.code ?? null } : {}),
        ...(hasDiscount ? {
          couponData: {
            couponType: discountType,
            discountPrice,
          },
        } : {}),
        shippingAmount: (ready && shippingLine) ? this.convertToIntegerWithoutDecimal(shippingLine.price.amount) : null,
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async createCheckout({
    email, shippingAddress, lineItems,
  }: createCheckout): Promise<{isReady: boolean, checkoutId: string}> {
    try {
      const input = { shippingAddress, lineItems, email };

      const { data, errors } = await this.client.request(checkoutCreateMutation, {
        variables: {
          input,
        },
      });

      if (errors?.graphQLErrors) {
        console.log('graphQLErrors: ', errors.graphQLErrors);
        handleGraphQLRequestErrors(errors.graphQLErrors);
      }

      if (data.checkoutCreate.checkoutUserErrors.length > 0) {
        console.log('checkoutUserErrors', data.checkoutCreate.checkoutUserErrors);
        handleCheckoutUserErrors(data.checkoutCreate.checkoutUserErrors, input.lineItems);
      }

      const { ready, id } = data.checkoutCreate.checkout;

      return {
        isReady: ready,
        checkoutId: data?.checkoutDiscountCodeApplyV2?.checkout?.id || id,
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  // this can handle updating address, line items, discounts, etc depending what is passed
  async updateCheckout({
    checkoutId, shippingAddress, lineItems, email,
  }: {
    checkoutId: string,
    shippingAddress: any,
    lineItems: LineItems[],
    email: string,
  }): Promise<{error?: string, checkoutId: string}> {
    try {
      let updatedCheckoutId = checkoutId;

      if (shippingAddress) {
        const { data, errors } = await this.client.request(checkoutShippingAddressUpdateMutation, {
          variables: {
            checkoutId: updatedCheckoutId,
            shippingAddress,
          },
        });

        if (errors?.graphQLErrors) {
          console.log('graphQLErrors: ', errors);
          handleGraphQLRequestErrors(errors.graphQLErrors);
        }

        if (data.checkoutShippingAddressUpdateV2.checkoutUserErrors.length > 0) {
          handleCheckoutUserErrors(data.checkoutShippingAddressUpdateV2.checkoutUserErrors);
        }

        updatedCheckoutId = data.checkoutShippingAddressUpdateV2.checkout.id;
      }

      if (lineItems.length > 0) {
        const { data, errors } = await this.client.request(checkoutLineItemsUpdateMutation, {
          variables: {
            checkoutId: updatedCheckoutId,
            lineItems,
          },
        });

        if (errors?.graphQLErrors) {
          console.log('graphQLErrors: ', errors);
          handleGraphQLRequestErrors(errors.graphQLErrors);
        }

        if (data.checkoutLineItemsReplace.userErrors.length > 0) {
          handleCheckoutUserErrors(data.checkoutLineItemsReplace.userErrors, lineItems);
        }

        updatedCheckoutId = data.checkoutLineItemsReplace.checkout.id;
      }

      if (email) {
        const { data, errors } = await this.client.request(checkoutEmailUpdateMutation, {
          variables: {
            checkoutId: updatedCheckoutId,
            email,
          },
        });

        if (errors?.graphQLErrors) {
          console.log('graphQLErrors: ', errors);
          handleGraphQLRequestErrors(errors.graphQLErrors);
        }

        if (data.checkoutEmailUpdateV2.checkoutUserErrors.length > 0) {
          handleCheckoutUserErrors(data.checkoutEmailUpdateV2.checkoutUserErrors);
        }

        updatedCheckoutId = data.checkoutEmailUpdateV2.checkout.id;
      }

      return {
        checkoutId: updatedCheckoutId,
      };
    } catch (err: any) {
      console.log(err);
      throw err;
    }
  }

  async updateCheckoutShippingLine({ checkoutId, shippingRateHandle }: {checkoutId: string, shippingRateHandle: string}):Promise<void> {
    try {
      const { data, errors } = await this.client.request(checkoutShippingLineUpdateMutation, {
        variables: {
          checkoutId,
          shippingRateHandle,
        },
      });

      if (errors?.graphQLErrors) {
        console.log('graphQLErrors: ', errors);
        handleGraphQLRequestErrors(errors.graphQLErrors);
      }

      if (data.checkoutShippingLineUpdate.checkoutUserErrors.length > 0) {
        handleCheckoutUserErrors(data.checkoutShippingLineUpdate.checkoutUserErrors);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async applyCoupon({ checkoutId, couponCode }: {checkoutId: string, couponCode: string}): Promise<ICouponResponse> {
    try {
      const { data: checkout } = await this.client.request(applyCouponQuery, {
        variables: {
          checkoutId,
          discountCode: couponCode,
        },
      });

      const { id, discountApplications } = checkout.checkoutDiscountCodeApplyV2.checkout;
      const respObj: ICouponResponse = { errors: [], checkoutId: id };

      if (checkout.checkoutDiscountCodeApplyV2.checkoutUserErrors.length > 0) {
        respObj.errors.push('Promo code is invalid');
      }

      /*
        Shopify will now give an error since the coupon is a valid one.
        If items are not part of the coupon it will just return the order with no discount applied
        TODO figure out why it does not return as https://community.shopify.com/c/hydrogen-headless-and-storefront/handle-discounts-via-the-storefront-api/m-p/1308445
      */
      if (!discountApplications || discountApplications?.edges?.length === 0) {
        if (couponCode === 'UPGRADE1TO2G7X9Z4') {
          respObj.errors.push('Promo code is invalid with these items or email.');
        } else {
          respObj.errors.push('Promo code does not apply to these items');
        }
      }

      return respObj;
    } catch (err: any) {
      // TODO  refactor err message handling
      console.log(err);
      throw new CouponError(err.message);
    }
  }

  async removeCoupon({ checkoutId }: {checkoutId: string}): Promise<ICouponResponse> {
    try {
      const { data: checkout } = await this.client.request(removeCouponQuery, {
        variables: {
          checkoutId,
        },
      });

      const { id } = checkout.checkoutDiscountCodeRemove.checkout;
      const respObj: ICouponResponse = { errors: [], checkoutId: id };

      if (checkout.checkoutDiscountCodeRemove.checkoutUserErrors.length > 0) {
        respObj.errors.push('Something went wrong. Please try again.');
      }

      return respObj;
    } catch (err) {
      console.log(err);
      throw new CouponError('Something went wrong. Please try again.');
    }
  }

  /*
    parseFloat will convert amount to a number with decimal
    multiplying by 100 will shift the decimal point two places to the right. For example 89.12 will become 8912 or 89.0 will be 8900
    Math.round rounds the number to the nearest integer
  */
  /* eslint-disable class-methods-use-this */
  private convertToIntegerWithoutDecimal(amount: string): number {
    return Math.round(parseFloat(amount) * 100);
  }

  private formatProductData(data: IShopifyProduct[]): FormattedProduct[] {
    return data.map((edge: IShopifyProduct) => {
      const prod = edge?.node;
      return {
        id: prod?.id,
        title: prod?.title,
        description: prod?.description,
        image: prod?.featuredImage?.url,
        images: prod?.images?.edges?.map((img) => img.node.url) ?? [],
        productType: prod?.productType,
        tags: prod?.tags,
        variants: prod?.variants?.edges?.map((variantEdge: IShopifyProductVariant) => {
          const variant = variantEdge?.node;
          /*
          TODO key value and name value are here so the shopify data will work with our old code.
          When refactoring / turning off the isShopifyon flag we can think about renaming these or only using id instead of key/etc.
          */
          return {
            id: variant?.id,
            key: variant?.id,
            name: variant?.title,
            title: variant?.title,
            sku: variant?.sku,
            compareAtPrice: (variant.compareAtPrice) ? this.convertToIntegerWithoutDecimal(variant.compareAtPrice.amount) : null,
            price: this.convertToIntegerWithoutDecimal(variant?.price?.amount) || 0,
            uid: variant?.uid?.value.trim() ?? null,
          };
        }),
        // TODO should we name this something like stripeData?
        metadata: {
          stripeSubId: prod?.stripeSubId?.value.trim(),
          stripeTrialDays: prod?.stripeTrialDays?.value.trim(),
          stripeCoupon: prod?.stripeCoupon?.value.trim(),
          membershipGID: prod?.membershipGID?.value.trim(),
          monthlyPrice: prod?.monthlyPrice?.value.trim(),
        },
      };
    });
  }
}

function getProductsOrCollectionData(queryResp: QueryCollectionReponse | QueryProductsResponse): GetProductsOrCollectionDataReturn {
  if (queryResp?.data && 'products' in queryResp.data) {
    return {
      productData: queryResp.data.products.edges,
      hasNextPage: queryResp.data.products.pageInfo.hasNextPage,
      endCursor: queryResp.data.products.pageInfo.endCursor,
    };
  }

  if (queryResp?.data && 'collection' in queryResp.data) {
    return {
      productData: queryResp.data.collection.products.edges,
      hasNextPage: queryResp.data.collection.products.pageInfo.hasNextPage,
      endCursor: queryResp.data.collection.products.pageInfo.endCursor,
    };
  }

  return {
    productData: [],
    hasNextPage: false,
    endCursor: '',
  };
}
